import React from 'react'
import { Color } from 'types/color'
import { Size } from 'types/size'

type FontWeight =
  | 'thin'
  | 'extralight'
  | 'light'
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold' /* 700 */
  | 'extrabold'
  | 'black'
type TextTags = 'span' | 'p' | 'pre'
export interface TextProps {
  as?: TextTags
  className?: string
  color?: Color
  size?: Size
  isTruncated?: boolean
  fontWeight?: FontWeight
}

const Text: React.FC<TextProps> = ({
  as = 'span',
  className = '',
  color,
  size,
  children,
  fontWeight,
  isTruncated,
  ...props
}) => {
  const DynamicTag = `${as}` as keyof JSX.IntrinsicElements
  const sizeClass = size ? FontSizeMap[size] : ''
  const colorClass = color ? `text-${color}` : ''
  const truncateClass = isTruncated ? 'truncate' : ''
  const fontWeightClass = fontWeight ? `font-${fontWeight}` : ''

  return (
    <DynamicTag
      className={`${sizeClass} ${colorClass} ${truncateClass} ${fontWeightClass} ${className}`}
      {...props}
    >
      {children}
    </DynamicTag>
  )
}

export default Text

const FontSizeMap: Record<Size, string> = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
}
