import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'
import { Size } from 'types/size'

export interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  padding?: Size
}

const Row: React.FC<RowProps> = React.forwardRef<HTMLDivElement, RowProps>(
  ({ className, padding, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={classNameBuilder(
          'flex flex-row',
          paddingClassMap[padding],
          className
        )}
        {...props}
      >
        {children}
      </div>
    )
  }
)

export default Row

const paddingClassMap: Record<Size, string> = {
  xs: 'p-xs',
  sm: 'p-sm',
  md: 'p-md',
  lg: 'p-lg',
  xl: 'p-xl',
}
