import Column from 'components/atoms/column'
import React from 'react'

export interface BodyProps {
  className?: React.HTMLAttributes<HTMLDivElement>['className']
}

const Body: React.FC<BodyProps> = ({ className, children }) => {
  return (
    <Column className={className ?? 'relative flex-1 shrink-0'}>
      {children}
    </Column>
  )
}

export default Body
