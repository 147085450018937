import ErrorIlustraComic from 'assets/svg/error-ilustra-comic.svg'
import Body from 'components/atoms/body'
import Column from 'components/atoms/column'
import Row from 'components/atoms/row'
import Text from 'components/atoms/text'
import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'

export interface ErrorScreenProps {
  message?: string
}
const ErrorScreen: React.FC<ErrorScreenProps> = ({ message }) => {
  return (
    <>
      <Body>
        <Column className="h-screen w-full flex-col items-center justify-center px-32 sm:flex-row-reverse">
          <Row className="w-full flex-col items-center justify-center sm:flex-row-reverse">
            <ErrorIlustraComic className="sm:h-[14.18rem] sm:w-[18.160rem] md:h-[17rem] md:w-[21.8rem] lg:h-[22.75rem] lg:w-[29rem]" />
            <Column className="mt-lg sm:mt-0">
              <Row className="sm:flex-col">
                <Text
                  className={classNameBuilder(
                    `text-lg font-bold text-indigo-500 lg:text-3xl`,
                    !message && 'hidden'
                  )}
                >
                  Oops!
                </Text>
                <Text
                  className={classNameBuilder(
                    'ml-1 text-lg font-bold text-indigo-500 sm:ml-0 lg:text-3xl',
                    message
                      ? 'whitespace-nowrap sm:whitespace-normal'
                      : 'text-center sm:text-left'
                  )}
                >
                  {!message
                    ? 'O recurso que você está acessando não está mais disponível.'
                    : message}
                </Text>
              </Row>
              <Text className="text-center text-xs font-normal text-indigo-500 sm:text-left md:text-sm">
                Recarregue a página.
              </Text>
            </Column>
          </Row>
        </Column>
      </Body>
    </>
  )
}

export default ErrorScreen
